import React from "react";
import Landing from "../components/LandingPage/Landing";
import {graphql} from "gatsby";
import {connect} from "react-redux";
import SEO from "../components/SEO";

const HKLanding = (props) => {
  return(
    <>
        <SEO {...(props?.data?.seo?.frontmatter || {})}/>
        <Landing {...props}/>;
    </>
  )
}

const mapStateToProps = (props) => {
    return props;
};
export default connect(mapStateToProps, null)(HKLanding);


export const query = graphql`
fragment TestimonialsFragment on MarkdownRemark {
      frontmatter {
      title
      testimonials {
        description
        userName
        date
        location
      }
    }
    
  }
  query DiamondsOnlinePageQuery {
    seo: markdownRemark(frontmatter: {templateKey: {eq: "online-diamonds"}}) {
        frontmatter {
            title
            description
        }
  }
  enTestimonials: markdownRemark(frontmatter: {templateKey: {eq: "en-testimonials"}}) {
   ...TestimonialsFragment
  }
  deTestimonials: markdownRemark(frontmatter: {templateKey: {eq: "de-testimonials"}}) {
   ...TestimonialsFragment
  }
  esTestimonials: markdownRemark(frontmatter: {templateKey: {eq: "es-testimonials"}}) {
   ...TestimonialsFragment
  }
  frTestimonials: markdownRemark(frontmatter: {templateKey: {eq: "fr-testimonials"}}) {
   ...TestimonialsFragment
  }
  heTestimonials: markdownRemark(frontmatter: {templateKey: {eq: "he-testimonials"}}) {
   ...TestimonialsFragment
  }
  itTestimonials: markdownRemark(frontmatter: {templateKey: {eq: "it-testimonials"}}) {
   ...TestimonialsFragment
  }
  jaTestimonials: markdownRemark(frontmatter: {templateKey: {eq: "ja-testimonials"}}) {
   ...TestimonialsFragment
  }
  zhTestimonials: markdownRemark(frontmatter: {templateKey: {eq: "zh-testimonials"}}) {
   ...TestimonialsFragment
  }
 }
`;