import React, {Component} from "react";
import Slider from 'react-slick'
import FrontFooter from "../FrontStatic/common/Front-Footer";
import FrontWrapper from "../FrontStatic/common/FrontWrapper";
import Consultation from "../FrontStatic/Consultation";
const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000
};

class Landing extends Component {
    render() {
        const { locale } = this.props.commonData.locale;
        const testimonials = this.props?.data?.[`${locale}Testimonials`]?.frontmatter;
        return (
            <FrontWrapper location={this.props.location}>
                <div className="scroll-content-wrapper" data-scrollbar>
                    <div className="page-main page-current">
                        <div className="page-toload legacy-page" data-bodyclassname="legacy">
                            <main className="page-content" role="main">
                                <div className="ldPage">
                                    <section className="banner-section-wrap">
                                        <div className="container">
                                            <div className="banner-content">
                                                <h2 className="title-text offset-text main-offset">
                                                    Being the leading Diamond Manufacturer and Exporters
                                                </h2>
                                                <h6 className="small-caps">
                                                    We are also the Diamond Wholesalers that offers its customers a
                                                    platform to Buy Diamonds Online with Ultra HD resolution 360- Degree
                                                    Videos of Diamonds & Certs from reputed labs on <a
                                                    href="/">hk.co</a>
                                                </h6>
                                                <h6 className="small-caps withLinkTour">
                                                    <a href="#quickContact" rel="noopener noreferrer">Buy Now</a>
                                                </h6>
                                            </div>
                                        </div>
                                    </section>
                                    <section className="masterpiece pad-100">
                                        <div className="container">
                                            <div className="serviceHead">
                                                <h2 className="serviceTitle mb-xs-20">The sparkle of HK diamond...</h2>
                                            </div>
                                            <div className="masterpiece_slider">
                                                <div className="card" style={{backgroundColor: '#f0f2fd'}}>
                                                    <a href="#quickCotact" className="wrapper-title"><span
                                                        className="title">Certified Diamonds</span></a>
                                                    <div className="icon icon-wave-inverted">
                                                        <svg id="icon-wave-inverted" viewBox="0 0 80 32">
                                                            <title>Wave inverted</title>
                                                            <path
                                                                d="M17.2 14.3c4.8 9.7 12.2 16.9 22.8 16.9s17.8-7.6 22.8-16.9c3.6-6.7 7.1-11.8 14-13.9 0.6-0.2 2-0.4 3.2-0.4h-80c0.8 0 1.7 0.2 2.4 0.4 6.7 2.1 10.8 5.7 14.8 13.9z"></path>
                                                        </svg>
                                                    </div>
                                                    <div className="icon icon-arrow-up" style={{fill: '#283895'}}>
                                                        <svg id="icon-arrow-up" viewBox="0 0 54 32"><title>Arrow
                                                            up</title>
                                                            <path
                                                                d="M52.812 23.646l-22.229-22.229c-1.905-1.905-5.032-1.905-6.937 0l-22.229 22.229c-0.879 0.879-1.417 2.15-1.417 3.469s0.489 2.54 1.417 3.469c0.928 0.928 2.15 1.417 3.469 1.417s2.54-0.489 3.469-1.417l18.76-18.76 18.809 18.809c0.873 0.861 2.072 1.392 3.395 1.392s2.523-0.532 3.396-1.393l-0.001 0.001c1.905-1.905 1.954-5.032 0.098-6.986z"></path>
                                                        </svg>
                                                    </div>
                                                    <div className="wrapper-img" style={{justifyContent: 'center'}}>
                                                        <img src={require('../../../static/img/diamond-certified.png')} alt=""/>
                                                    </div>
                                                    <div className="content">
                                                        <div className="wrapper-headband">
                                                            <div className="icon icon-wave">
                                                                <svg id="icon-wave" viewBox="0 0 80 32"><title>
                                                                    Wave</title>
                                                                    <path
                                                                        d="M0 0v32h80v-32c-1.2 0-2.576 0.214-3.2 0.4-6.868 2.049-10.4 7.2-13.998 13.892-5.005 9.308-12.17 16.946-22.802 16.946s-18-7.238-22.802-16.946c-4.036-8.161-8.077-11.781-14.798-13.892-0.728-0.229-1.6-0.4-2.4-0.4z"></path>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                        <div className="links-intro">
                                                            <ul>
                                                                <li>
                                                                    Brilliant Round Cut diamonds
                                                                </li>
                                                                <li>
                                                                    More than 8 different Fancy Shapes
                                                                </li>
                                                                <li>
                                                                    0.30 Cents to 50 plus cart-sizes
                                                                </li>
                                                                <li>
                                                                    Third-party certifications from GIA, IGI, HRD, GSI,
                                                                    GSL,
                                                                    and NGTC
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className="wrapper-links">
                                                            <div className="wrapper-link" style={{color: '#9fcdfb'}}>
                                                                <h6 className="small-caps withLinkTour">
                                                                    <a href="#quickContact" rel="noopener noreferrer">Buy
                                                                        Now</a>
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card" style={{backgroundColor: '#f0f2fd'}}>
                                                    <a href="#quickCotact" className="wrapper-title"><span
                                                        className="title">Non certified Diamonds</span></a>
                                                    <div className="icon icon-wave-inverted">
                                                        <svg id="icon-wave-inverted" viewBox="0 0 80 32"><title>Wave
                                                            inverted</title>
                                                            <path
                                                                d="M17.2 14.3c4.8 9.7 12.2 16.9 22.8 16.9s17.8-7.6 22.8-16.9c3.6-6.7 7.1-11.8 14-13.9 0.6-0.2 2-0.4 3.2-0.4h-80c0.8 0 1.7 0.2 2.4 0.4 6.7 2.1 10.8 5.7 14.8 13.9z"></path>
                                                        </svg>
                                                    </div>
                                                    <div className="icon icon-arrow-up" style={{fill: '#283895'}}>
                                                        <svg id="icon-arrow-up" viewBox="0 0 54 32"><title>Arrow
                                                            up</title>
                                                            <path
                                                                d="M52.812 23.646l-22.229-22.229c-1.905-1.905-5.032-1.905-6.937 0l-22.229 22.229c-0.879 0.879-1.417 2.15-1.417 3.469s0.489 2.54 1.417 3.469c0.928 0.928 2.15 1.417 3.469 1.417s2.54-0.489 3.469-1.417l18.76-18.76 18.809 18.809c0.873 0.861 2.072 1.392 3.395 1.392s2.523-0.532 3.396-1.393l-0.001 0.001c1.905-1.905 1.954-5.032 0.098-6.986z"></path>
                                                        </svg>
                                                    </div>
                                                    <div className="wrapper-img" style={{justifyContent: 'center'}}>
                                                        <img src={require('../../../static/img/non-certified.png')}
                                                             alt=""/>
                                                    </div>
                                                    <div className="content">
                                                        <div className="wrapper-headband">
                                                            <div className="icon icon-wave">
                                                                <svg id="icon-wave" viewBox="0 0 80 32"><title>
                                                                    Wave</title>
                                                                    <path
                                                                        d="M0 0v32h80v-32c-1.2 0-2.576 0.214-3.2 0.4-6.868 2.049-10.4 7.2-13.998 13.892-5.005 9.308-12.17 16.946-22.802 16.946s-18-7.238-22.802-16.946c-4.036-8.161-8.077-11.781-14.798-13.892-0.728-0.229-1.6-0.4-2.4-0.4z"></path>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                        <div className="links-intro">
                                                            <ul>
                                                                <li>
                                                                    Brilliant Round Cut diamonds
                                                                </li>
                                                                <li>
                                                                    More than 8 different Fancy Shapes
                                                                </li>
                                                                <li>
                                                                    0.30 Cents to 50 plus cart-sizes
                                                                </li>
                                                                <li>
                                                                    In FL to I3 clarity
                                                                </li>
                                                                <li>
                                                                    In D to M Color
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className="wrapper-links">
                                                            <div className="wrapper-link" style={{color: '#9fcdfb'}}>
                                                                <h6 className="small-caps withLinkTour">
                                                                    <a href="#quickContact" rel="noopener noreferrer">Buy
                                                                        Now</a>
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card" style={{backgroundColor: '#f0f2fd'}}>
                                                    <a href="#quickCotact" className="wrapper-title">
                                                        <span className="title">Fancy Shape Diamonds</span>
                                                    </a>
                                                    <div className="icon icon-wave-inverted">
                                                        <svg id="icon-wave-inverted" viewBox="0 0 80 32">
                                                            <title>Wave inverted</title>
                                                            <path d="M17.2 14.3c4.8 9.7 12.2 16.9 22.8 16.9s17.8-7.6 22.8-16.9c3.6-6.7 7.1-11.8 14-13.9 0.6-0.2 2-0.4 3.2-0.4h-80c0.8 0 1.7 0.2 2.4 0.4 6.7 2.1 10.8 5.7 14.8 13.9z"></path>
                                                        </svg>
                                                    </div>
                                                    <div className="icon icon-arrow-up" style={{fill: '#283895'}}>
                                                        <svg id="icon-arrow-up" viewBox="0 0 54 32"><title>Arrow up</title>
                                                            <path d="M52.812 23.646l-22.229-22.229c-1.905-1.905-5.032-1.905-6.937 0l-22.229 22.229c-0.879 0.879-1.417 2.15-1.417 3.469s0.489 2.54 1.417 3.469c0.928 0.928 2.15 1.417 3.469 1.417s2.54-0.489 3.469-1.417l18.76-18.76 18.809 18.809c0.873 0.861 2.072 1.392 3.395 1.392s2.523-0.532 3.396-1.393l-0.001 0.001c1.905-1.905 1.954-5.032 0.098-6.986z"></path>
                                                        </svg>
                                                    </div>
                                                    <div className="wrapper-img" style={{justifyContent: 'center'}}>
                                                        <img src={require('../../../static/img/fancy.png')} alt=""/>
                                                    </div>
                                                    <div className="content">
                                                        <div className="wrapper-headband">
                                                            <div className="icon icon-wave">
                                                                <svg id="icon-wave" viewBox="0 0 80 32"><title>Wave</title>
                                                                    <path d="M0 0v32h80v-32c-1.2 0-2.576 0.214-3.2 0.4-6.868 2.049-10.4 7.2-13.998 13.892-5.005 9.308-12.17 16.946-22.802 16.946s-18-7.238-22.802-16.946c-4.036-8.161-8.077-11.781-14.798-13.892-0.728-0.229-1.6-0.4-2.4-0.4z"></path>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                        <div className="links-intro">
                                                            <ul>
                                                                <li>
                                                                    Brilliant Round Cut diamonds
                                                                </li>
                                                                <li>
                                                                    More than 8 different Fancy Shapes
                                                                </li>
                                                                <li>
                                                                    In red, blue, green, and many more colors
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className="wrapper-links">
                                                            <div className="wrapper-link" style={{color: '#9fcdfb'}}>
                                                                <h6 className="small-caps withLinkTour">
                                                                    <a href="#quickContact" rel="noopener noreferrer">Buy Now</a>
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card" style={{backgroundColor: '#f0f2fd'}}>
                                                    <a href="#quickCotact" className="wrapper-title"><span
                                                        className="title">Matching Pair of Diamonds</span></a>
                                                    <div className="icon icon-wave-inverted">
                                                        <svg id="icon-wave-inverted" viewBox="0 0 80 32">
                                                            <title>Wave inverted</title>
                                                            <path
                                                                d="M17.2 14.3c4.8 9.7 12.2 16.9 22.8 16.9s17.8-7.6 22.8-16.9c3.6-6.7 7.1-11.8 14-13.9 0.6-0.2 2-0.4 3.2-0.4h-80c0.8 0 1.7 0.2 2.4 0.4 6.7 2.1 10.8 5.7 14.8 13.9z"></path>
                                                        </svg>
                                                    </div>
                                                    <div className="icon icon-arrow-up" style={{fill: '#283895'}}>
                                                        <svg id="icon-arrow-up" viewBox="0 0 54 32"><title>Arrow
                                                            up</title>
                                                            <path
                                                                d="M52.812 23.646l-22.229-22.229c-1.905-1.905-5.032-1.905-6.937 0l-22.229 22.229c-0.879 0.879-1.417 2.15-1.417 3.469s0.489 2.54 1.417 3.469c0.928 0.928 2.15 1.417 3.469 1.417s2.54-0.489 3.469-1.417l18.76-18.76 18.809 18.809c0.873 0.861 2.072 1.392 3.395 1.392s2.523-0.532 3.396-1.393l-0.001 0.001c1.905-1.905 1.954-5.032 0.098-6.986z"></path>
                                                        </svg>
                                                    </div>
                                                    <div className="wrapper-img" style={{justifyContent: 'center'}}>
                                                        <img src={require('../../../static/img/matching-diamond.png')}
                                                             alt=""/>
                                                    </div>
                                                    <div className="content">
                                                        <div className="wrapper-headband">
                                                            <div className="icon icon-wave">
                                                                <svg id="icon-wave" viewBox="0 0 80 32">
                                                                    <title>Wave</title>
                                                                    <path
                                                                        d="M0 0v32h80v-32c-1.2 0-2.576 0.214-3.2 0.4-6.868 2.049-10.4 7.2-13.998 13.892-5.005 9.308-12.17 16.946-22.802 16.946s-18-7.238-22.802-16.946c-4.036-8.161-8.077-11.781-14.798-13.892-0.728-0.229-1.6-0.4-2.4-0.4z"></path>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                        <div className="links-intro">
                                                            <ul>
                                                                <li>
                                                                    In all shapes
                                                                </li>
                                                                <li>
                                                                    As per your required
                                                                </li>
                                                                <li>
                                                                    Color, Carats, and Clarity
                                                                </li>

                                                            </ul>
                                                        </div>
                                                        <div className="wrapper-links">
                                                            <div className="wrapper-link" style={{color: '#9fcdfb'}}>
                                                                <h6 className="small-caps withLinkTour"><a
                                                                    href="#quickContact" rel="noopener noreferrer">Buy
                                                                    Now</a></h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <div className="section-container withPaddO">
                                        <div className="journy-countainer">
                                            <div className="partners left">
                                                <h2 className="show-text sub-head padd-max js-type-animation-alt">
                                                    Our Mining Compliance (Source)
                                                </h2>
                                                <div className="partners-container d-flex align-items-center">
                                                    <a href="http://gss.debeersgroup.com/customer-directory/hari-krishna-exports-pvt-ltd"
                                                       className="partners-logo">
                                                        <img src={require('../../../static/img/sightholder.png')}
                                                             alt=""/>
                                                    </a>
                                                    <a href="http://www.riotinto.com/" className="partners-logo">
                                                        <img src={require('../../../static/img/rio-tinto.png')} alt=""/>
                                                    </a>
                                                    <a href="https://www.ddmines.com/" className="partners-logo">
                                                        <img src={require('../../../static/img/dominion.png')} alt=""/>
                                                    </a>
                                                    <a href="http://sales.alrosa.info/" className="partners-logo">
                                                        <img src={require('../../../static/img/alrosa.png')} alt=""/>
                                                    </a>

                                                </div>
                                            </div>


                                            <div className="partners right">
                                                <h2 className="show-text sub-head padd-max js-type-animation-alt">
                                                    Marks of Superiority
                                                </h2>
                                                <div className="partners-container d-flex align-items-center">
                                                    <a href="http://www.canadamark.com/" className="partners-logo">
                                                        <img src={require('../../../static/img/canadamark.png')}
                                                             alt=""/>
                                                    </a>

                                                    <a href="http://www.forevermark.com/" className="partners-logo">
                                                        <img src={require('../../../static/img/forevermark.png')}
                                                             alt=""/>
                                                    </a>
                                                    <a href="http://www.responsiblejewellery.com/member/hari-krishna-exports/"
                                                       className="partners-logo">
                                                        <img src={require('../../../static/img/rjc.png')} alt=""/>
                                                    </a>
                                                    <a href="https://www.worlddiamondcouncil.org/"
                                                       className="partners-logo">
                                                        <img src={require('../../../static/img/wdc.png')} alt=""/>
                                                    </a>

                                                </div>
                                            </div>


                                            <div className="partners left">
                                                <h2 className="show-text sub-head padd-max js-type-animation-alt">
                                                    Third Party Certifications - Quality
                                                </h2>
                                                <div className="partners-container d-flex align-items-center">
                                                    <a href="https://www.gia.edu/" className="partners-logo">
                                                        <img src={require('../../../static/img/gia.png')} alt=""/>
                                                    </a>
                                                    <a href="https://www.egllaboratories.org/"
                                                       className="partners-logo">
                                                        <img src={require('../../../static/img/egl.png')} alt=""/>
                                                    </a>
                                                    <a href="https://www.igi.org/" className="partners-logo">
                                                        <img src={require('../../../static/img/igi.png')} alt=""/>
                                                    </a>
                                                    <a href="https://www.hrdantwerp.com/en" className="partners-logo">
                                                        <img src={require('../../../static/img/hrd.png')} alt=""/>
                                                    </a>
                                                    <a href="https://www.gemstudieslaboratory.com.au/"
                                                       className="partners-logo">
                                                        <img src={require('../../../static/img/gsl.png')} alt=""/>
                                                    </a>
                                                    <a href="http://www.gsiworldwide.com/" className="partners-logo">
                                                        <img src={require('../../../static/img/gsi.png')} alt=""/>
                                                    </a>
                                                    <a href="https://www.iidgr.com/" className="partners-logo">
                                                        <img src={require('../../../static/img/iidg.png')} alt=""/>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <section id="quickContact" className="contact-form">
                                        <div className="container">
                                            <div className="contact-form-bn" style={{background: 'transparent'}}>
                                                <div className="serviceHead text-center">
                                                    <h2 className="serviceTitle mb-xs-20">Hunting any preferred
                                                        diamonds?</h2>
                                                    <p>Contact our experts to get the help you find the perfect
                                                        diamonds. Book a free consultation.</p>
                                                </div>
                                                <Consultation />
                                            </div>
                                        </div>
                                    </section>
                                    <section className="searvicesHome">
                                        <div className="container">
                                            <div className="servicesTwoSections d-xs-block">
                                                <div className="servicesLeft">
                                                    <div className="serviceHead">
                                                        <h2 className="serviceTitle mb-xs-20">Panorama of our
                                                            Products</h2>
                                                    </div>
                                                    <div className="serviceContentArea">
                                                        <div className="plasticCards__type">
                                                            <div className="plasticCards__typeItem">
                                                                <div className="plasticCards__typeHead">
                                                                    <a href="https://hk.co/wholesale-diamonds"
                                                                       className="plasticCards__typeTitle">
                                                                        Wholesale Diamonds
                                                                    </a>
                                                                    <div className="plasticCards__arrowIcon">
                                                                        <svg viewBox="64 64 896 896" focusable="false"
                                                                             className="" data-icon="right" width="1em"
                                                                             height="1em" fill="currentColor"
                                                                             aria-hidden="true">
                                                                            <path
                                                                                d="M765.7 486.8L314.9 134.7A7.97 7.97 0 0 0 302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 0 0 0-50.4z"></path>
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="plasticCards__typeDescription textDescription">
                                                                    We offer an exclusive collection of wholesale
                                                                    diamonds
                                                                    in Brilliant Round Cut and more than 9 different
                                                                    Fancy
                                                                    Shapes, ranging from 0.30 to 50 carats plus in size
                                                                    with
                                                                    FL to I3 clarity and D to M Color.
                                                                    <div className="plasticCards__shadow"></div>
                                                                </div>
                                                            </div>
                                                            <div className="plasticCards__typeItem">
                                                                <div className="plasticCards__typeHead">
                                                                    <a href="https://hk.co/certified-diamonds"
                                                                       className="plasticCards__typeTitle">Certified
                                                                        Diamonds</a>
                                                                    <div className="plasticCards__arrowIcon">
                                                                        <svg viewBox="64 64 896 896" focusable="false"
                                                                             className="" data-icon="right" width="1em"
                                                                             height="1em" fill="currentColor"
                                                                             aria-hidden="true">
                                                                            <path
                                                                                d="M765.7 486.8L314.9 134.7A7.97 7.97 0 0 0 302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 0 0 0-50.4z"></path>
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="plasticCards__typeDescription textDescription">
                                                                    We offer an exclusive collection of certified
                                                                    diamonds
                                                                    with in-house Faith Certificate along with
                                                                    third-party
                                                                    certifications from GIA, IGI, HRD, GSI, GSL, and
                                                                    NGTC
                                                                    upon request.
                                                                    <div className="plasticCards__shadow"></div>
                                                                </div>
                                                            </div>
                                                            <div className="plasticCards__typeItem">
                                                                <div className="plasticCards__typeHead">
                                                                    <a href="https://hk.co/loose-diamonds"
                                                                       className="plasticCards__typeTitle">Parcel
                                                                        Diamonds</a>
                                                                    <div className="plasticCards__arrowIcon">
                                                                        <svg viewBox="64 64 896 896" focusable="false"
                                                                             className="" data-icon="right" width="1em"
                                                                             height="1em" fill="currentColor"
                                                                             aria-hidden="true">
                                                                            <path
                                                                                d="M765.7 486.8L314.9 134.7A7.97 7.97 0 0 0 302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 0 0 0-50.4z"></path>
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="plasticCards__typeDescription textDescription">
                                                                    We offer an exclusive collection of diamonds in
                                                                    Brilliant Round Cut and more than 9 different Fancy
                                                                    Shapes, ranging from 0.30 to 50 carats plus in size
                                                                    with
                                                                    FL to I3 clarity and D to M Color.
                                                                    <div className="plasticCards__shadow"></div>
                                                                </div>
                                                            </div>
                                                            <div className="plasticCards__typeItem">
                                                                <div className="plasticCards__typeHead">
                                                                    <a href="https://hk.co/fancy-diamonds"
                                                                       className="plasticCards__typeTitle">Fancy Color
                                                                        Diamonds</a>
                                                                    <div className="plasticCards__arrowIcon">
                                                                        <svg viewBox="64 64 896 896" focusable="false"
                                                                             className="" data-icon="right" width="1em"
                                                                             height="1em" fill="currentColor"
                                                                             aria-hidden="true">
                                                                            <path
                                                                                d="M765.7 486.8L314.9 134.7A7.97 7.97 0 0 0 302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 0 0 0-50.4z"></path>
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="plasticCards__typeDescription textDescription">
                                                                    We also offer fancy color loose diamonds in the red,
                                                                    blue, green, and many more colors.
                                                                    <div className="plasticCards__shadow"></div>
                                                                </div>
                                                            </div>

                                                            <div className="plasticCards__typeItem">
                                                                <div className="plasticCards__typeHead">
                                                                    <a className="plasticCards__typeTitle">Non-certified
                                                                        Diamonds</a>
                                                                    <div className="plasticCards__arrowIcon">
                                                                        <svg viewBox="64 64 896 896" focusable="false"
                                                                             className="" data-icon="right" width="1em"
                                                                             height="1em" fill="currentColor"
                                                                             aria-hidden="true">
                                                                            <path
                                                                                d="M765.7 486.8L314.9 134.7A7.97 7.97 0 0 0 302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 0 0 0-50.4z"></path>
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="plasticCards__typeDescription textDescription">
                                                                    We offer non-certified diamonds in Round, Pear,
                                                                    Oval,
                                                                    Marquise, Emerald, and many more shapes.
                                                                    <div className="plasticCards__shadow"></div>
                                                                </div>
                                                            </div>
                                                            <div className="plasticCards__typeItem">
                                                                <div className="plasticCards__typeHead">
                                                                    <a className="plasticCards__typeTitle">
                                                                        Matching Pair of Diamonds
                                                                    </a>
                                                                    <div className="plasticCards__arrowIcon">
                                                                        <svg viewBox="64 64 896 896" focusable="false"
                                                                             className="" data-icon="right" width="1em"
                                                                             height="1em" fill="currentColor"
                                                                             aria-hidden="true">
                                                                            <path
                                                                                d="M765.7 486.8L314.9 134.7A7.97 7.97 0 0 0 302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 0 0 0-50.4z"></path>
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="plasticCards__typeDescription textDescription">
                                                                    You can also find matching pairs of diamonds in all
                                                                    shapes as per your requirement.
                                                                    <div className="plasticCards__shadow"></div>
                                                                </div>
                                                            </div>
                                                            <h6 className="small-caps withLinkTour"><a
                                                                href="#quickContact"
                                                                rel="noopener noreferrer">Buy
                                                                Now</a></h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="servicesRight">
                                                    <img className="value-desktop-view"
                                                         src={require('../../../static/img/service.png')} alt=""/>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <section className="global">
                                        <div className="container">
                                            <div className="serviceHead text-center">
                                                <h2 className="serviceTitle mb-xs-20">New Global Advantage
                                                    Opportunity</h2>
                                                <p>We offer the following benefits to the valuable customers like you on
                                                    the
                                                    volume purchases of all our certified products.</p>
                                            </div>
                                            <div className="adv_table">
                                                <div className="adv_row">
                                                    <h4>Sr. No.</h4>
                                                    <h4>On your purchase of</h4>
                                                    <h4>Advantage we offer</h4>
                                                </div>
                                                <div className="adv_row">
                                                    <p>1</p>
                                                    <p>US$ 50,000 to US$ 99,999</p>
                                                    <p>0.50 %</p>
                                                </div>
                                                <div className="adv_row">
                                                    <p>2</p>
                                                    <p>US$ 1,00,000 to US$ 1,99,999</p>
                                                    <p>1.00 %</p>
                                                </div>
                                                <div className="adv_row">
                                                    <p>3</p>
                                                    <p>US$ 2,00,000 to US$ 2,99,999</p>
                                                    <p>1.50 %</p>
                                                </div>
                                                <div className="adv_row">
                                                    <p>4</p>
                                                    <p>US$ 3,00,000 to US$ 3,99,999</p>
                                                    <p>2.00 %</p>
                                                </div>
                                                <div className="adv_row">
                                                    <p>5</p>
                                                    <p>US$ 4,00,000 to US$ 5,99,999</p>
                                                    <p>2.50 %</p>
                                                </div>
                                                <div className="adv_row">
                                                    <p>6</p>
                                                    <p>US$ 6,00,000 to US$ 7,99,999</p>
                                                    <p>3.00 %</p>
                                                </div>
                                                <div className="adv_row">
                                                    <p>7</p>
                                                    <p>US$ 8,00,000 to US$ 9,99,999</p>
                                                    <p>3.50 %</p>
                                                </div>
                                                <div className="adv_row">
                                                    <p>8</p>
                                                    <p>US$ 10,00,000 and above</p>
                                                    <p>4.00 %</p>
                                                </div>

                                            </div>
                                            <p className="more">*T&C applied. <a href="https://hk.co/policy">Learn
                                                More</a>
                                            </p>
                                            <h6 className="small-caps withLinkTour"><a href="#quickContact"
                                                                                       rel="noopener noreferrer">Buy
                                                Now</a>
                                            </h6>
                                        </div>
                                    </section>
                                    <section className="excellence">
                                        <div className="container">
                                            <div className="excel_row">
                                                <div className="excel">
                                                    <figure>
                                                        <img src={require('../../../static/img/gems.svg')} alt=""/>
                                                    </figure>
                                                    <div className="contents">
                                                        <h4>Diamond Manufacturer</h4>
                                                        <p>Shaping and Polishing a Raw Beauty being a Dazzling Marvel we
                                                            all
                                                            admire.</p>
                                                    </div>
                                                </div>
                                                <div className="excel">
                                                    <figure>
                                                        <img src={require('../../../static/img/d_export.svg')} alt=""/>
                                                    </figure>
                                                    <div className="contents">
                                                        <h4>Diamond Exporter</h4>
                                                        <p>Taking our craftsmanship and brilliance to over 80+
                                                            countries,
                                                            including several retail chains in the USA, Europe, and the
                                                            Far
                                                            East, and Australia.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="excel_row middle">
                                                <div className="excel">

                                                    <div className="serviceHead text-center">
                                                        <h2 className="serviceTitle mb-xs-20">Our Spheres of
                                                            Excellence</h2>
                                                        <p>The Hari Krishna Group is a leading player in the global
                                                            diamond
                                                            industry, donning the hats of the diamond manufacturer &
                                                            exporter and diamond jewellery manufacturer & exporter.</p>
                                                        <h6 className="small-caps withLinkTour"><a href="#quickContact"
                                                                                                   rel="noopener noreferrer">Connect
                                                            to HK</a></h6>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="excel_row">
                                                <div className="excel">
                                                    <figure>
                                                        <img src={require('../../../static/img/ring.svg')} alt=""/>
                                                    </figure>
                                                    <div className="contents">
                                                        <h4>Diamond Jewellery Manufacturer</h4>
                                                        <p>Our Jewellery comes perfectly crafted in Innovative Designs
                                                            style
                                                            crafted with the Highest Quality to make a lasting
                                                            impression.</p>
                                                    </div>
                                                </div>
                                                <div className="excel">
                                                    <figure>
                                                        <img src={require('../../../static/img/earrings.svg')} alt=""/>
                                                    </figure>
                                                    <div className="contents">
                                                        <h4>Diamond Jewellery Exporter</h4>
                                                        <p>Our expertise and innovativeness has spread our radiance as a
                                                            leading Jewellery Exporters from India to the USA, Hong
                                                            Kong,
                                                            UK, Israel, UAE, and Belgium.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <section className="masterpiece pad-100 bg-light">
                                        <div className="container">
                                            <div className="serviceHead text-center">
                                                <h2 className="serviceTitle mb-xs-20">Our Masterpieces</h2>
                                            </div>
                                            <div className="marsterpiece-wrap">
                                                <div className="marterpiece">
                                                    <img src={require('../../../static/img/emerald-criss.png')} alt=""/>
                                                    <h4>Emerald Criss Cut</h4>
                                                </div>
                                                <div className="marterpiece">
                                                    <img src={require('../../../static/img/cushion-lamour.png')}
                                                         alt=""/>
                                                    <h4>Cushion Lamour Cut</h4>
                                                </div>
                                                <div className="marterpiece">
                                                    <img src={require('../../../static/img/emerald-lamour.png')}
                                                         alt=""/>
                                                    <h4>Emerald Lamour Cut</h4>
                                                </div>
                                                <div className="marterpiece">
                                                    <img src={require('../../../static/img/oval-lamour.png')} alt=""/>
                                                    <h4>Oval Lamour Cut</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <div className="testimonial_wrap">
                                        <div className="container">
                                            <div className="serviceHead text-center">
                                                <h2 className="serviceTitle mb-xs-20">{testimonials?.title}</h2>
                                            </div>
                                            <div className="testimonial_slider">
                                                <Slider {...settings}>
                                                {
                                                    testimonials?.testimonials?.map(testimonial => (

                                                        <div className="testimonial">
                                                        <div className="name_ini">{testimonial?.userName?.charAt(0)}</div>
                                                        <h4>
                                                            {testimonial?.userName}
                                                        </h4>
                                                        <p>{testimonial?.description}</p>
                                                    </div>

                                                    ))
                                                }
                                                </Slider>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <FrontFooter />
                            </main>
                        </div>
                    </div>
                </div>
            </FrontWrapper>
        );
    }
}

export default Landing;
